import React, { Fragment, FunctionComponent, RefObject, useContext } from 'react'
import { CdcAd, CdcUserFolder, FolderWidgetType } from '@bbx/common/types/UserFeed'
import { HorizontalSlider, ITEM_HEIGHT_JOBS } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { css } from 'styled-components'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import {
    DividerWithNegativeMargin,
    FeedWidgetWrapper,
    WidgetIcon,
} from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { AdBadges } from '@bbx/search-journey/common/components/AdvertSummary/AdBadges'
import { Box } from '@wh-components/core/Box/Box'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { getPulseAdId, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'
import { Button } from '@wh-components/core/Button/Button'
import { ContextLink, findContextLinkWithId, findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { Text } from '@wh-components/core/Text/Text'
import { getSimilarAds } from '@bbx/search-journey/common/api/similarAdsApiClient'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { toast } from '@wh-components/core/Toast/Toast'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { containsOnlyAdsFromVertical } from '@bbx/search-journey/common/components/AdvertSummary/containsOnlyAdsFromVertical'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import FavoriteIcon from '@wh-components/icons/Favorite'

interface FolderWidgetProps {
    widget: FolderWidgetType
}

export const FolderWidget: FunctionComponent<FolderWidgetProps> = ({ widget }) => {
    return (
        <FeedWidgetWrapper
            heading={widget.userFolders.length > 1 ? widget.title : undefined}
            backgroundColor="palette.petrol"
            icon={FavoriteIcon}
            linkText="zur Übersicht"
            href="/iad/myprofile/myfindings"
            id={`widget-index-${widget.listIndex}`}
            type={widget.type}
        >
            {widget.userFolders.map((userFolder, index) => {
                return (
                    <Fragment key={userFolder.title}>
                        <FolderWidgetSlider
                            userFolder={userFolder}
                            title={widget.userFolders.length === 1 ? widget.title : userFolder.title}
                            titleWithIcon={widget.userFolders.length === 1}
                        />
                        {index !== widget.userFolders.length - 1 && <DividerWithNegativeMargin marginVertical="m" />}
                    </Fragment>
                )
            })}
        </FeedWidgetWrapper>
    )
}

export const FolderWidgetSlider: FunctionComponent<{
    userFolder: CdcUserFolder
    title: string
    titleWithIcon: boolean
}> = ({ userFolder, title, titleWithIcon }) => {
    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'FolderWidget',
        adIds: userFolder.ads.map((ad) => getPulseAdId(ad)).slice(0, numberOfAdsLimit),
        pulseMetadata: userFolder.pulseMetadata,
    })
    const taggingData = useFeedEmptyTaggingData()

    const isOnlyBap = containsOnlyAdsFromVertical(verticalIdMap.BAP, userFolder.ads)

    const onClickLink = () => {
        callActionEvent('ad_widget_show_all_click', taggingData, getWidgetAndPage(userFolder.jobs))
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(userFolder.jobs))
    }

    const onSimilarAdButtonClick = (ad: CdcAd, similarAdsContextLink: ContextLink) => () => {
        callActionEvent('startpage_myfolder_widget_show_similar_ads_button_click', taggingData, getWidgetAndPage(userFolder.jobs))
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(userFolder.jobs))
        const extractContextLinkFromSearchResult = (searchResult: SearchResult) => {
            let redirectLink
            if (verticalIdMap.BAP !== ad.verticalId) {
                return (
                    searchResult &&
                    buildSeoUrl(findContextLinkWithId('similarAdsSearchLink', searchResult.searchContextLinks)?.relativePath)
                )
            } else {
                redirectLink =
                    searchResult && findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath
                return redirectLink ? buildSeoUrl(redirectLink) : undefined
            }
        }

        const fetchContextLink = async () => {
            try {
                const searchResult = await getSimilarAds(similarAdsContextLink)
                const redirectLink = extractContextLinkFromSearchResult(searchResult)
                if (redirectLink) {
                    await BbxRouter.push({
                        href: redirectLink,
                        clientSideRouting: true,
                    })
                } else {
                    toast('Leider haben wir keine ähnlichen Anzeigen gefunden', {
                        type: 'warning',
                    })
                }
            } catch (err) {
                toast('Leider haben wir keine ähnlichen Anzeigen gefunden', {
                    type: 'warning',
                })
            }
        }
        fetchContextLink()
    }

    const userFolderLink = userFolder.jobs ? '/jobs/meinjob/anzeigen' : '/iad/myprofile/myfindings'

    return (
        <HorizontalSlider
            heading={
                <FolderWidgetSubHeader
                    title={title}
                    href={userFolderLink}
                    onClick={onClickLink}
                    visibilityTrackingRef={visibilityTrackingRef}
                    titleWithIcon={titleWithIcon}
                />
            }
            testIdPrefix="folder-widget"
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            href={userFolderLink}
            onClick={onClickLink}
            onClickButton={() => {
                callActionEvent('ad_widget_show_all_button_click', taggingData, getWidgetAndPage(userFolder.jobs))
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(userFolder.jobs))
            }}
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
            numberOfAdsLimit={numberOfAdsLimit}
            variant={isOnlyBap ? 'portrait' : undefined}
            buttonHeight={userFolder.jobs ? ITEM_HEIGHT_JOBS : undefined}
        >
            {userFolder.ads.slice(0, numberOfAdsLimit).map((ad, index) => {
                const badgeTestId = `ad-badges-${ad.id}`
                const similarAdsContextLink = findContextLinkWithIdFromArray('similarAdsRecommendationUrl', ad.contextLinkList)
                const isAdActive = ad.advertStatus.id === adStatusFlag.ACTIVE || ad.advertStatus.id === adStatusFlag.RESERVED
                return (
                    <Box position="relative" key={ad.id}>
                        <CdcAdvertSummaryDiscriminator
                            ad={ad}
                            testIdPrefix="folder"
                            isActive={isAdActive}
                            variant={isOnlyBap ? 'portrait' : 'landscape'}
                            onClickTaggingEvent={() => {
                                callActionEvent('ad_widget_ad_click', taggingData, {
                                    ...getWidgetAndPage(userFolder.jobs),
                                    adId: getPulseAdId(ad),
                                    adIndex: index + 1,
                                    listName: userFolder.pulseMetadata?.listName,
                                })
                                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(userFolder.jobs))
                            }}
                        />
                        {!isAdActive && similarAdsContextLink ? (
                            <Button
                                position="absolute"
                                display="block"
                                top="90px"
                                left="0"
                                right="0"
                                marginLeft="auto"
                                marginRight="auto"
                                width={{ phone: '80%', desktop: '90%' }}
                                height="auto"
                                minHeight="32px"
                                paddingVertical="xs"
                                onClick={onSimilarAdButtonClick(ad, similarAdsContextLink)}
                                color="primary"
                                type="button"
                                size="small"
                            >
                                <Text
                                    display="block"
                                    lineHeight="1.2"
                                    as="p"
                                    css={css`
                                        white-space: pre-wrap;
                                    `}
                                >
                                    Zeig mir Ähnliches
                                </Text>
                            </Button>
                        ) : undefined}
                        <AdBadges
                            advertStatus={ad.advertStatus}
                            attributes={ad.attributes}
                            position="absolute"
                            top="xs"
                            left="xs"
                            testId={badgeTestId}
                        />
                    </Box>
                )
            })}
        </HorizontalSlider>
    )
}

export const FolderWidgetSubHeader: FunctionComponent<{
    title: string
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    titleWithIcon: boolean
    href?: string
    onClick?: () => void
}> = ({ title, visibilityTrackingRef, titleWithIcon = false, href, onClick }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']

    return (
        <Box display="flex" ref={visibilityTrackingRef}>
            {titleWithIcon && <WidgetIcon icon={FavoriteIcon} backgroundColor="palette.petrol" />}
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <ClientRoutingAnchorLink
                        color={titleColor ?? 'palette.verydarkgrey'}
                        href={href}
                        onClick={onClick}
                        type="anchor"
                        css={css`
                            outline: 0;
                        `}
                    >
                        <Text lineClamp={1} fontWeight="bold" fontSize={titleWithIcon ? 'l' : undefined}>
                            {title}
                        </Text>
                    </ClientRoutingAnchorLink>
                </Box>
            </Box>
        </Box>
    )
}

const getWidgetAndPage = (isJobs: boolean) => ({
    taggingId: isJobs ? 'Jobs' : undefined,
    taggingWidgetType: 'FolderWidget',
    source: 'homepage',
    page: 'Startpage',
})
